AFRAME.registerComponent("ui-text-box", {
    schema: {
        value: {type: "string", default: "Text"},
        color: {type: "color", default: "black"},
        width: {type: "number", default: 1},
        height: {type: "number", default: 0.2},
        "wrap-count": {type: "number", default: 30},
    },
    init: function () {
        const data = this.data;
        const el = this.el;

        el.setAttribute("geometry", {
            primitive: "plane",
            width: data.width,
            height: data.height,
        });
        el.setAttribute("material", {
            color: "white",
            shader: "flat",
        });

        const textEl = document.createElement("a-entity");
        textEl.setAttribute("text", {
            value: data.value,
            color: data.color,
            baseline: "top",
            align: "left",
            width: data.width,
            wrapCount: data['wrap-count'],
        });
        textEl.setAttribute("position", {x: 0, y: data.height/2, z: 0});
        el.appendChild(textEl);
        this.textEl = textEl;
    },
    update: function (oldData) {
        const modifiedData = AFRAME.utils.diff(oldData, this.data);

        for (const modifiedKey in modifiedData) {
            switch (modifiedKey) {
                case "height":
                case "width":
                case "value":
                case "color":
                case "wrap-count":
                    this.textEl.setAttribute("text", {
                        value: this.data.value,
                        color: this.data.color,
                        baseline: "top",
                        align: "left",
                        width: this.data.width,
                        wrapCount: this.data['wrap-count'],
                    });
                    break;
            }
        }
    },
});

const extendDeep = AFRAME.utils.extendDeep;
const meshMixin = AFRAME.primitives.getMeshMixin();
AFRAME.registerPrimitive("a-text-box", extendDeep({}, meshMixin, {
    defaultComponents: {
        "ui-text-box": {value: "Text"}
    },
    mappings: {
        value: "ui-text-box.value",
        color: "ui-text-box.color",
        width: "ui-text-box.width",
        height: "ui-text-box.height",
        "wrap-count": "ui-text-box.wrap-count",
    }
}));
